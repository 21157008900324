@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

@font-face {
  font-family: "LicensePlate";
  src: url('../../public/fonts/UKNumberPlate.ttf') format('truetype');
}

::-webkit-scrollbar {
  width: 4px;
  margin-left: 15px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: none;
  padding: 3px 0;
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: none;
}

body,
html {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  height: 100%;
  color: rgb(38, 45, 62);
  background-color: rgb(238, 245, 249);
  overflow: hidden;
}

#root {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}