@import '../util.scss';

.card {
  @include timing-function;
  transition-property: filter;
  background-color: $white;
  border-radius: $rounded-md;
  width: 100%;
  border: 1px solid $gray-300;
  overflow: hidden;
  padding: 20px;
  filter: $shadow-none;

  &.noPad {
    padding: 0 !important;
  }

  &.hover:hover {
    filter: $shadow-md;
    cursor: pointer;
  }

  .title {
    display: flex;
    align-items: center;
    padding: 32px 32px 16px 32px;

    .icon {
      margin-right: 8px;
      width: 28px;
      height: 28px;
    }

    .content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $text-xl;
      font-weight: 600;
    }
  }

  .body {
    padding: 0 32px 32px 32px;
  }
}