.speechBubble {
  position: absolute;
  z-index: 1000;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-52%);
  width: 145px;
  background-color: white;
  border: solid 1px rgb(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));

  &:before {
    content: "";
    position: absolute;
    bottom: -13px;
    left: 50%;
    height: 9px;
    width: 9px;
    background: white;
    transform: rotate(45deg) translateX(-50%);
    transform-origin: top right;
    border-bottom: inherit;
    border-right: inherit;
  }
}