.radioButton::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 100px;
  opacity: 0;
  transition: opacity 150ms;
}

.radioButton:checked::after {
  opacity: 1;
}