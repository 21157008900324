@import '../util.scss';

.input {
  @include transition-colors;
  padding: 12px;
  border-radius: $rounded-md;
  border: 1px solid $gray-300;
  width: 100%;
  font-size: $text-lg;

  &:focus {
    outline: none;
    border: 1px solid $blue-400;
  }
}

.inputGroup {
  width: 100%;

  .label {
    font-size: $text-sm;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .error {
    font-size: $text-sm;
    color: $red-600;
  }
}

.buttonInputGroup {
  width: 100%;
  display: flex;

  .input {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .button {
    flex: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}